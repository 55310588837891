<template>
  <div class="row" v-if="Object.keys( user ).length > 0">

    <div class="col col-xl-7 px-0 px-lg-3">
      <div class="card shadow">
        <div class="card-body">
          <div class="card-title">
            <div class="d-flex flex-wrap justify-content-between">
              <div>
                <router-link :to="'/users/' + user.id" v-if="$can(['read-user'])"
                             class="btn btn-outline-primary mr-3">
                  Подробнее
                </router-link>
              </div>
              <button @click="handleSubmitForm" class="btn btn-success mt-2 mt-lg-0"
                      :disabled="$wait.waiting('updateUser')">
                <span v-if="!$wait.waiting('updateUser')">
                  <i class="icons8-save"></i>Сохранить
                </span>
                <BeatLoader v-else
                            :loading="$wait.waiting('updateUser')"
                            :color="'#ffffff'"
                            :size="'20px'">
                </BeatLoader>
              </button>
            </div>
          </div>

          <form id="user-edit-form" autocomplete="off"
                @submit.stop.prevent="handleSubmitForm">

            <fieldset :disabled="$wait.waiting('updateUser')">

              <div v-if="!isUser"
                   class="form-group row" :class="{'has-error': errors.has('email')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Email</label>
                <div class="col">
                  <input v-model.trim="user.email"
                         v-validate="'required|email|max:191'"
                         data-vv-as="Email"
                         name="email"
                         type="text"
                         class="form-control">
                </div>
                <div v-show="errors.has('email')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('email') }}
                </div>
              </div>

              <div v-if="!isUser"
                   class="form-group row" :class="{'has-error': errors.has('password')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Пароль</label>
                <div class="col input-group">
                  <input v-model="user.password"
                         v-validate="'required|min:6'"
                         data-vv-as="Пароль"
                         name="password"
                         :type="passwordChanged ? 'text' : 'password'"
                         class="form-control"
                         readonly>
                  <div class="input-group-append">
                    <div @click="getGeneratedPassword()"
                         class="btn btn-info input-group-addon">
                      <div v-show="!$wait.waiting('getGeneratedPassword')">
                        <i class="icons8-refresh"></i>
                        Обновить
                      </div>
                      <BeatLoader v-show="$wait.waiting('getGeneratedPassword')"
                                  :loading="$wait.waiting('getGeneratedPassword')"
                                  :color="'#ffffff'"
                                  :size="'20px'">
                      </BeatLoader>
                    </div>
                  </div>
                </div>
                <div v-show="errors.has('password')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('password') }}
                </div>
              </div>

              <div class="form-group row" :class="{'has-error': errors.has('surname')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Фамилия</label>
                <div class="col">
                  <input v-model.trim="user.surname"
                         v-validate="'required|max:30'"
                         data-vv-as="Фамилия"
                         name="surname"
                         type="text"
                         class="form-control">
                </div>
                <div v-show="errors.has('surname')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('surname') }}
                </div>
              </div>

              <div class="form-group row" :class="{'has-error': errors.has('name')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Имя</label>
                <div class="col">
                  <input v-model.trim="user.name"
                         v-validate="'required|max:30'"
                         data-vv-as="Имя"
                         name="name"
                         type="text"
                         class="form-control">
                </div>
                <div v-show="errors.has('name')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('name') }}
                </div>
              </div>

              <div class="form-group row" :class="{'has-error': errors.has('patronymic')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Отчество</label>
                <div class="col">
                  <input v-model.trim="user.patronymic"
                         v-validate="'max:30'"
                         data-vv-as="Отчество"
                         name="patronymic"
                         type="text"
                         class="form-control">
                </div>
                <div v-show="errors.has('patronymic')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('patronymic') }}
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Магазин</label>
                <div class="col input-group">
                  <multiselect v-model="user.shop.data"
                               :options="shops"
                               :selectLabel="'Нажмите для выбора'"
                               :selectedLabel="'Выбрано'"
                               :deselectLabel="'Нажмите, чтобы удалить'"
                               placeholder="Выберите один вариант"
                               label="name"
                               track-by="name">
                    <div slot="noOptions">Магазины отсутствуют</div>
                    <div slot="noResult">Ничего не найдено</div>
                  </multiselect>
                </div>
              </div>

              <div v-if="!isUser" class="form-group row">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Роль</label>
                <div class="col input-group">
                  <multiselect v-model="user.roles.data[0]"
                               :options="roles"
                               :selectLabel="'Нажмите для выбора'"
                               :selectedLabel="'Выбрано'"
                               :allow-empty="false"
                               deselect-label="Роль обязательный параметр"
                               placeholder="Выберите один вариант"
                               label="name"
                               track-by="name">
                  </multiselect>
                </div>
              </div>

              <div v-if="!isUser" class="form-group row">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Статус</label>
                <div class="col input-group">
                  <select v-model="user.status" class="form-control">
                    <option :value="3">Активен</option>
                    <option :value="5">Заблокирован</option>
                  </select>
                </div>
              </div>

            </fieldset>

            <button @click="deleteUser" v-if="$can(['delete-user'])"
                    type="button" class="btn btn-outline-danger float-right">
              <i class="icons8-trash"></i>
              Удалить
            </button>

          </form>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { nullification } from '../../utils/batch'

  export default {
    name: 'UserEdit',

    metaInfo() {
      return {
        title: this.title,
      }
    },

    data() {
      return {
        title: '',
        user: {},
        roles: [],
        shops: [],
        passwordChanged: false,
      }
    },

    computed: {
      ...mapGetters( {
        isUser: 'auth/isUser',
      } ),

      isOwner() {
        return this.$store.getters['auth/userId'] === this.$route.params.id
      },
    },

    created() {
      // todo only admin can edit yourself

      if ( this.$can( ['read-user'] ) ) {
        this.$wait.start( 'user findUserById' )

        this.$api.user.findUserById( this.$route.params.id, 'roles,shop' ).then( res => {
          if ( res.status === 200 && res.data.data ) {
            let user = res.data.data

            this.user = user
            this.fullName = ( `${user.surname || ''} ${user.name}  ${user.patronymic || ''}` ).trim()
            this.title = 'Редактирование ' + user.name
          }
        } ).finally( () => {
          this.$wait.end( 'user findUserById' )
        } )
      }

      if ( this.$can( ['read-role'] ) ) {
        this.$wait.start( 'user findAllRoles' )

        this.$api.role.findAllRoles().then( res => {
          if ( res.status === 200 && res.data.data ) {
            this.roles = res.data.data.filter( role => {
              if ( this.$store.getters['auth/userRole'] === 'super' ) {
                return role.name !== 'super' // only one super
              }

              if ( this.$store.getters['auth/userRole'] === 'admin' ) {
                return role.name !== 'super' && role.name !== 'admin'
              }
            } )
          }
        } ).finally( () => {
          this.$wait.end( 'user findAllRoles' )
        } )
      }

      if ( this.$can( ['read-shop'] ) ) {
        this.$wait.start( 'shop findAllShops' )

        this.$api.shop.findAllShops().then( res => {
          if ( res.status === 200 && res.data.data ) {
            this.shops = res.data.data
          }
        } ).finally( () => {
          this.$wait.end( 'shop findAllShops' )
        } )
      }
    },

    beforeRouteEnter( to, from, next ) {
      next( vm => {
        if ( to.params.id.length !== parseInt( process.env.VUE_APP_HASH_ID_LENGTH ) ) {
          vm.$router.push( { name: 'NotFound' } )
        } else {
          const userRole = vm.$store.getters['auth/userRole']

          // hotfix-user-list error 404 for super user for all user edit pages

          // only super can edit yourself
          /*if ( userRole === 'super' && vm.$store.getters['auth/userId'] !== to.params.id ) {
            vm.$router.push( { name: 'NotFound' } )
          }*/

          // hotfix-user-list

          if ( vm.$store.getters['auth/userId'] === to.params.id
            || userRole === 'super'
            || userRole === 'admin'
            || userRole === 'manager'
          ) {
            return next()
          } else {
            vm.$router.push( { name: 'NotFound' } )
          }
        }
      } )
    },

    methods: {
      handleSubmitForm() {
        this.$validator.validateAll().then( validated => {
          if ( validated ) {
            let formData = nullification( {
              shop_id: this.user.shop.data ? this.user.shop.data.id : null,
              role_id: this.user.roles.data[0].id,
              email: this.user.email,
              name: this.user.name,
              surname: this.user.surname,
              patronymic: this.user.patronymic,
              password: this.user.password,
              status: this.user.status,
            } )

            this.$wait.start( 'updateUser' )

            this.$api.user.updateUser( this.user.id, formData, 'roles,shop' ).then( res => {
              if ( res.status === 200 && res.data.user.data ) {
                let user = res.data.user.data

                this.user = user
                this.fullName = ( `${user.surname || ''} ${user.name}  ${user.patronymic || ''}` ).trim()
                this.title = 'Редактирование ' + user.surname || user.name
                this.passwordChanged = false

                this.$toasted.show( res.data.message, { type: 'success' } )
              }
            } ).finally( () => {
              this.$wait.end( 'updateUser' )
            } )
          }
        } )
      },

      async getGeneratedPassword() {
        this.$wait.start( 'getGeneratedPassword' )

        let res = await this.$api.user.generatePassword()

        if ( res.data ) {
          this.passwordChanged = true
          this.$set( this.user, 'password', res.data )
        }

        this.$wait.end( 'getGeneratedPassword' )
      },

      async deleteUser() {
        this.$modal.show( 'dialog', {
          title: 'Удалить пользователя?',
          text: 'Это действие нельзя отменить.',
          buttons: [
            {
              title: 'Удалить',
              default: true,
              handler: async () => {
                this.$wait.start( 'deleteUser' )

                let res = await this.$api.user.deleteUser( this.$route.params.id )

                if ( res.status === 200 ) {
                  this.$modal.hide( 'dialog' )
                  this.$toasted.show( res.data.message, { type: 'success' } )
                  this.$router.push( '/users' )
                }

                this.$wait.end( 'deleteUser' )
              },
            },
            {
              title: 'Отмена',
              handler: () => {
                this.$modal.hide( 'dialog' )
              },
            },
          ],
        } )
      },
    },
  }
</script>
