import forEach from 'lodash/forEach'
import store from '../store/index'

export function nullification( formData ) {
  return forEach( formData, ( value, key ) => {
    if ( typeof value === 'string' ) {
      value = value.trim()
    }

    formData[key] = value === '' ? null : value
  } )
}

export function loadPage( pagePath ) {
  return () => import(/* webpackChunkName: "page-[request]" */ `@/pages/${pagePath}.vue`)
}

export function removeAuthDataFromStorage() {
  localStorage.removeItem( 'access_token' )
  localStorage.removeItem( 'refresh_token' )
  localStorage.removeItem( 'expires_in' )
}

export function setAuthDataToStorage( accessToken, refreshToken, expiresIn ) {
  localStorage.setItem( 'access_token', accessToken )
  localStorage.setItem( 'refresh_token', refreshToken )
  localStorage.setItem( 'expires_in', expiresIn )
}

export function isAccessTokenExpiresSoon() {
  const expiresIn = 1800 // update tokens if access token expires soon (less then 30 min)
  const accessTokenExpiresIn = localStorage.getItem( 'expires_in' )

  return accessTokenExpiresIn - Math.ceil( Date.now() / 1000 ) < expiresIn
}

/**
 * {Array} permissions Like ['create-shop'] or ['create-shop', 'read-shop']
 * {Object} object To check for is owner
 */
export function canAbility( permissions, object = null ) {
  if ( store.state.auth.permissions.length === 0 ) {
    return false
  }

  if ( Array.isArray( permissions ) ) {
    for ( const permission of permissions ) {
      if ( !store.state.auth.permissions.includes( permission ) ) {
        return false
      }
    }
  } else {
    console.error( 'permissions not an array' )

    return
  }

  if ( object ) {
    if ( !object.created_by || typeof object.created_by !== 'string' ) {
      console.error( 'object don\'t have created_by field or field is not string' )

      return
    }

    let canByIsOwner = object.created_by === store.getters['auth/userId']

    if ( !canByIsOwner ) {
      return false
    }
  }

  return true
}
