<template>
  <div class="row" v-if="Object.keys( shop ).length > 0">

    <div class="col col-xl-7 px-0 px-lg-3">
      <div class="card shadow">
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="card-title">
              <span class="mr-3">
                <i class="icons8-market" title="Иконка магазинчика"></i>{{ shopNameOrCode }}
              </span>
              <router-link :to="'/shops/' + shop.id" class="btn btn-outline-primary mr-3">
                Подробнее
              </router-link>
            </div>
            <button @click="handleSubmitForm" class="btn btn-success"
                    :disabled="$wait.waiting('updateShop')">
              <span v-if="!$wait.waiting('updateShop')">
                <i class="icons8-save"></i>Сохранить
              </span>
              <BeatLoader v-else
                          :loading="$wait.waiting('updateShop')"
                          :color="'#ffffff'"
                          :size="'20px'">
              </BeatLoader>
            </button>
          </div>

          <form id="user-edit-form" autocomplete="off"
                @submit.stop.prevent="handleSubmitForm">

            <fieldset :disabled="$wait.waiting('updateShop')">

              <div class="form-group row" :class="{'has-error': errors.has('name')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Название</label>
                <div class="col">
                  <input v-model.trim="shop.name"
                         v-validate="'max:191'"
                         data-vv-as="Название"
                         name="name"
                         type="text"
                         class="form-control">
                </div>
                <div v-show="errors.has('name')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('name') }}
                </div>
              </div>

              <div class="form-group row" :class="{'has-error': errors.has('code')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Код</label>
                <div class="col">
                  <input v-model.trim="shop.code"
                         v-validate="'required|max:191'"
                         data-vv-as="Код"
                         name="code"
                         type="text"
                         class="form-control">
                </div>
                <div v-show="errors.has('code')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('code') }}
                </div>
              </div>

              <div class="form-group row" :class="{'has-error': errors.has('address')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Адрес</label>
                <div class="col">
                  <input v-model.trim="shop.address"
                         v-validate="'max:191'"
                         data-vv-as="Адрес"
                         name="address"
                         type="text"
                         class="form-control">
                </div>
                <div v-show="errors.has('address')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('address') }}
                </div>
              </div>

              <div class="form-group row" :class="{'has-error': errors.has('address')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Город</label>
                <div class="col">
                  <multiselect v-model="shop.city.data"
                               :options="cities"
                               :loading="$wait.waiting('asyncFindCity')"
                               :internal-search="false"
                               :clear-on-select="true"
                               :close-on-select="true"
                               :selectLabel="'Нажмите для выбора'"
                               :selectedLabel="'Выбрано'"
                               :deselectLabel="'Нажмите, чтобы удалить'"
                               :tagPlaceholder="'Введите язык'"
                               label="name"
                               track-by="name"
                               @search-change="asyncFindCity"
                               placeholder="Начните писать город...">
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">{{ props.option.name }}</span>
                        <br>
                        <span class="option__small text-muted">
                          ({{ props.option.region.data.name }})
                        </span>
                      </div>
                    </template>
                    <div slot="noOptions">Список пуст</div>
                    <span slot="noResult">Не найден ни один город с таким названием</span>
                  </multiselect>
                </div>
                <div v-show="errors.has('address')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('address') }}
                </div>
              </div>

              <div class="form-group row" :class="{'has-error': errors.has('description')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Описание</label>
                <div class="col">
                <textarea v-model="shop.description"
                          v-validate="'max:3000'"
                          data-vv-as="Описание"
                          name="description"
                          rows="5"
                          title="Описание магазина"
                          class="form-control">
                </textarea>
                </div>
                <div v-show="errors.has('description')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('description') }}
                </div>
              </div>

            </fieldset>

            <button @click="deleteShop" type="button" class="btn btn-outline-danger float-right">
              <i class="icons8-trash"></i>
              Удалить
            </button>

          </form>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import debounce from 'lodash/debounce'
  import { nullification } from '../../utils/batch'

  export default {
    name: 'ShopEdit',

    metaInfo() {
      return {
        title: this.title,
      }
    },

    data() {
      return {
        title: '',
        shop: {},
        shopNameOrCode: '',
        cities: [],
      }
    },

    created() {
      this.$wait.start( 'findShopById' )

      this.$api.shop.findShopById( this.$route.params.id, 'city' ).then( res => {
        if ( res.status === 200 && res.data.data ) {
          let shop = res.data.data

          this.shop = shop
          this.shopNameOrCode = shop.name || shop.code
          this.title = 'Редактирование ' + shop.name || shop.code
        }
      } ).finally( () => {
        this.$wait.end( 'findShopById' )
      } )
    },

    beforeRouteEnter( to, from, next ) {
      if ( to.params.id.length !== parseInt( process.env.VUE_APP_HASH_ID_LENGTH ) ) {
        next( vm => {
          vm.$router.push( { name: 'NotFound' } )
        } )
      } else {
        return next()
      }
    },

    methods: {
      handleSubmitForm() {
        this.$validator.validateAll().then( validated => {
          if ( validated ) {
            let formData = nullification( {
              city_id: this.shop.city.data ? this.shop.city.data.id : null,
              name: this.shop.name,
              address: this.shop.address,
              code: this.shop.code,
              description: this.shop.description,
            } )

            this.$wait.start( 'updateShop' )

            this.$api.shop.updateShop( this.shop.id, formData, 'city' ).then( res => {
              if ( res.status === 200 && res.data.shop.data ) {
                let shop = res.data.shop.data

                this.shop = shop
                this.shopNameOrCode = shop.name || shop.code
                this.title = 'Редактирование ' + shop.name || shop.code

                this.$toasted.show( res.data.message, { type: 'success' } )
              }
            } ).finally( () => {
              this.$wait.end( 'updateShop' )
            } )
          }
        } )
      },

      asyncFindCity:
        debounce( function ( query ) {
          this.$wait.start( 'asyncFindCity' )

          if ( query.length > 1 ) {
            this.$api.city.findCityByName( query, 'region' ).then( res => {
              this.cities = res.data.data
            } ).finally( () => {
              this.$wait.end( 'asyncFindCity' )
            } )
          } else {
            this.$wait.end( 'asyncFindCity' )
          }
        }, 200 ),

      async deleteShop() {
        this.$modal.show( 'dialog', {
          title: 'Удалить магазин?',
          text: 'Это действие нельзя отменить.',
          buttons: [
            {
              title: 'Удалить',
              default: true,
              handler: async () => {
                this.$wait.start( 'deleteShop' )

                let res = await this.$api.shop.deleteShop( this.$route.params.id )

                if ( res.status === 200 ) {
                  this.$modal.hide( 'dialog' )
                  this.$toasted.show( res.data.message, { type: 'success' } )
                  this.$router.push( '/shops' )
                }

                this.$wait.end( 'deleteShop' )
              },
            },
            {
              title: 'Отмена',
              handler: () => {
                this.$modal.hide( 'dialog' )
              },
            },
          ],
        } )
      },
    },
  }
</script>
