<template>
  <div class="logout">
    Logout
  </div>
</template>

<script>
  export default {
    name: 'Logout',

    metaInfo() {
      return {
        title: 'Выход',
      }
    },

    created() {
      this.$store.dispatch( 'auth/logoutUser' ).then( res => {
        if ( res.status === 204 ) {
          this.$centrifuge.disconnect()
          this.$router.push( '/login' )
        }
      } )
    },
  }
</script>
