<template>
  <div v-if="!$wait.waiting('findEventsForToday')" class="row">
    <div class="l-fullscreen d-flex justify-content-center align-items-start">

      <div v-show="!registrationOpened" class="card shadow col col-md-8 col-xl-6 px-0 px-md-3">
        <div class="card-body">
          <div class="text-center mb-2">
            <div v-if="eventsToday.length > 0">
              <span>Открыть регистрацию</span>
              <br>
              <small>(Сегодня доступно {{ eventsToday.length }})</small>
            </div>
            <h5 v-else class="text-info py-3">Сегодня мероприятия не запланированы.
              <img src="@/assets/img/smiles/icons8-sad-smile.png" title="Грустный" />
            </h5>
          </div>
          <div class="d-flex flex-wrap align-items-center justify-content-center">

            <div v-for="event in eventsToday"
                 :key="event.id"
                 @click="toggleSelectedEvent( event )"
                 :class="isEventSelected( event ) ? 'btn-success' : 'btn-outline-primary'"
                 class="btn mb-3 w-100 w-lg position-relative"
            >
              <i class="icons8-checked-2" v-if="isEventSelected( event )"></i>
              <span>{{ event.name | shortness(25) }}</span>
              <div class="d-flex justify-content-center align-items-center">
                <div v-if="event.event_type" class="badge badge-info mr-2">{{ event.event_type.data.name }}</div>
                <div>{{ event.opens_from }}</div>
              </div>
            </div>

            <button @click="openRegistration" v-show="eventsToday.length > 0"
                    :disabled="Object.keys( selectedEvent ).length === 0 || registrationQrError"
                    class="btn btn-success b-radius-2 btn-block">Далее
            </button>
          </div>
        </div>
      </div>

      <QrScoringPoints v-show="registrationOpened" />

    </div>
  </div>
</template>

<script>
  import QrScoringPoints from '../../components/qr/QrScoringPoints'

  export default {
    name: 'QrSelectEvent',

    components: {
      QrScoringPoints,
    },

    metaInfo() {
      return {
        title: this.title,
      }
    },

    data() {
      return {
        title: 'Открыть регистрацию',
        eventsToday: [],
      }
    },

    computed: {
      selectedEvent() {
        return this.$store.state.registrationQR.selectedEvent
      },

      registrationOpened() {
        return this.$store.state.registrationQR.opened
      },

      registrationQrError() {
        return this.$store.state.registrationQR.error
      },
    },

    created() {
      this.$wait.start( 'findEventsForToday' )

      this.$api.event.findEventsForToday().then( res => {
        if ( res.status === 200 && res.data.data ) {
          this.eventsToday = res.data.data
        }
      } ).finally( () => {
        this.$wait.end( 'findEventsForToday' )
      } )
    },

    methods: {
      isEventSelected( event ) {
        return this.selectedEvent.id === event.id
      },

      toggleSelectedEvent( event ) {
        if ( Object.keys( this.$store.state.registrationQR.selectedEvent ).length > 0 ) {
          this.$store.commit( 'SET_REGISTRATION_QR_SELECTED_EVENT', {} )
        } else {
          this.$store.commit( 'SET_REGISTRATION_QR_SELECTED_EVENT', event )
        }
      },

      openRegistration() {
        this.$store.commit( 'SET_REGISTRATION_QR_OPENED', true )
      },
    },
  }
</script>

<style scoped>
  .icons8-checked-2 {
    position: absolute;
    top: 0;
    right: 5px;
    font-size: 1.5rem;
  }
</style>
