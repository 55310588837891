import store from '../store/index'
import { isAccessTokenExpiresSoon } from '../utils/batch'

export default async function auth( { next, router } ) {
  const accessToken = localStorage.getItem( 'access_token' )
  const refreshToken = localStorage.getItem( 'refresh_token' )
  const user = store.getters['auth/user']

  if ( accessToken && refreshToken ) {
    if ( isAccessTokenExpiresSoon() ) {
      const res = await store.dispatch( 'auth/refreshToken', refreshToken )

      if ( res.status !== 200 ) {
        return next()
      }
    }

    if ( user ) {
      return next() // user in store, next middleware
    } else {
      const res = await store.dispatch( 'auth/fetchUser' )

      if ( res.status !== 200 ) {
        return next() // show login page
      } else {
        if ( store.getters['auth/userRole'] === 'user' ) {
          router.push( { name: 'UserDetail', params: { id: store.getters.userId } } )
        } else {
          router.push( { name: 'ScoreEventList' } )
        }
      }
    }
  } else {
    return next() // show login page
  }
}
