import axios from 'axios'
import router from '../router/index'
import store from '../store/index'
import app from '../main'
import findKey from 'lodash/findKey'

axios.defaults.baseURL = process.env.VUE_APP_API_URL + '/v1'

axios.interceptors.request.use( config => {
  config.headers['Content-Type'] = 'application/json'
  config.headers['Accept'] = 'application/json'
  config.headers['Accept-Language'] = 'ru'

  let token = localStorage.getItem( 'access_token' )

  if ( token ) {
    config.headers['Authorization'] = `Bearer ${token}`
  }

  if ( process.env.NODE_ENV === 'development' ) {
    console.log( 'Making request to ' + config.url )
  }

  app.$Progress.start()

  return config
} )

axios.interceptors.response.use( res => {
  if ( process.env.NODE_ENV === 'development' ) {
    console.log( 'res', res )
  }

  app.$Progress.finish()

  return res
}, error => {
  if ( process.env.NODE_ENV === 'development' ) {
    console.log( 'error ', error.response )
  }

  app.$Progress.fail()

  let status = error.response.status

  // bad request
  if ( status === 400 ) {
    app.$toasted.show( error.response.data.message, { type: 'error' } )
  }

  // token expired or other auth errors
  if ( status === 401 ) {
    app.$toasted.show( 'Вы не авторизованы. Выполните вход...', { type: 'error' } )

    localStorage.removeItem( 'access_token' )
    store.commit( 'auth/UNSET_USER' )
    router.push( { name: 'Login' } )
  }

  // entity not found
  if ( status === 404 ) {
    app.$toasted.show( error.response.data.message, { type: 'info' } )
  }

  // error validation
  if ( status === 422 ) {
    const errors = error.response.data.errors
    const lastKey = findKey( errors )
    app.$toasted.show( errors[lastKey][0], { type: 'error' } )
  }

  // ahtung
  if ( status === 500 ) {
    app.$toasted.show( 'Ошибка на сервере. Обратитесь к администрации.', { type: 'error' } )
  }

  return Promise.reject( error )
} )

export default axios
