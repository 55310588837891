import api from '../../api'
import { removeAuthDataFromStorage } from '../../utils/batch'
import { setAuthDataToStorage } from '../../utils/batch'
import {
  captureException as SentryException,
  configureScope as SentryConfigureScope,
} from '@sentry/vue'

export default {
  namespaced: true,

  state: {
    user: null,
    permissions: [],
  },

  mutations: {
    SET_USER( state, user ) {
      state.user = user
    },

    SET_PERMISSIONS( state, permissions ) {
      state.permissions = permissions
    },

    UNSET_USER( state ) {
      state.user = null
    },

    UNSET_PERMISSIONS( state ) {
      state.permissions = []
    },
  },

  actions: {
    async fetchUser( store ) {
      try {
        let res = await api.auth.me()

        let user = res.data.user.data
        let permissions = res.data.permissions.data.map( item => item.name )

        if ( res.status === 200 && user && permissions ) {
          store.commit( 'SET_USER', user )
          store.commit( 'SET_PERMISSIONS', permissions )

          SentryConfigureScope( scope => {
            scope.setUser( {
              id: user.id, username: user.surname || user.name || '', email: user.email,
            } )
          } )
        }

        return res
      } catch ( e ) {
        removeAuthDataFromStorage()

        // cause if auth.me() request fails, it's usually problem like server error.
        // normally auth.me() request should go only if access token is valid
        SentryException( e )

        return e.response
      }
    },

    async logoutUser( { commit } ) {
      try {
        const res = await api.auth.logout()

        // 401 token expired
        if ( res.status === 204 ) {
          removeAuthDataFromStorage()
          commit( 'UNSET_USER' )
          commit( 'UNSET_PERMISSIONS' )

          SentryConfigureScope( scope => {
            scope.setUser( {} )
          } )
        }

        return res
      } catch ( e ) {
        // 401 token expired
        if ( e.response.status === 401 ) {
          removeAuthDataFromStorage()
          commit( 'UNSET_USER' )
          commit( 'UNSET_PERMISSIONS' )

          SentryConfigureScope( scope => {
            scope.setUser( {} )
          } )
        }

        return e.response
      }
    },

    refreshToken( { state }, refreshToken ) {
      return api.auth.refresh( refreshToken ).then( res => {
        if ( res.status === 200 ) {
          setAuthDataToStorage( res.data.access_token, res.data.refresh_token, res.data.expires_in )
        }

        return res
      }, error => {
        console.log( 'error.status', error.response.status )
        removeAuthDataFromStorage()

        return error.response
      } )
    },
  },

  getters: {
    user: state => {
      return state.user ? state.user : null
    },

    userId: state => {
      return state.user ? state.user.id : null
    },

    userRole: state => {
      return state.user ? state.user.roles.data[0].name : null
    },

    isAdmin: ( state, getters ) => {
      return getters.userRole === 'admin'
    },

    isManager: ( state, getters ) => {
      return getters.userRole === 'manager'
    },

    isUser: ( state, getters ) => {
      return getters.userRole === 'user'
    },

    isAuth: state => state.user !== null,
  },
}
