import auth from '../middleware/auth'
import checkRole from '../middleware/checkRole'
import { loadPage } from '../utils/batch'

import QrSelectEvent from '../pages/qr/QrSelectEvent.vue'

export default [
  {
    path: '/qr',
    meta: {
      roles: ['super', 'admin', 'manager'],
      middleware: [auth, checkRole],
    },
    component: loadPage( 'qr/Qr' ),
    children: [
      {
        path: '',
        name: 'QrSelectEvent',
        meta: {
          roles: ['super', 'admin', 'manager'],
          middleware: [auth, checkRole],
          crumbs: '/Регистрация QR',
        },
        component: QrSelectEvent,
      },
    ],
  },
]
