<template>
  <div class="row" v-if="Object.keys( event ).length > 0">

    <div class="col-12" v-show="isEventDateExpired">
      <div class="alert alert-danger">
        <span>Нельзя изменить прошедшее мероприятие.</span>
      </div>
    </div>

    <div class="col col-xl-7 px-0 px-lg-3">
      <div class="card shadow">
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="card-title">
              <i class="icons8-event" title="Иконка мероприятия"></i>
              {{ eventName }}
              <router-link :to="'/events/' + event.id" class="btn btn-outline-primary ml-3">
                Подробнее
              </router-link>
            </div>
            <button @click="handleSubmitForm" class="btn btn-success"
                    v-show="!isEventDateExpired"
                    :disabled="$wait.waiting('updateEvent')">
              <span v-if="!$wait.waiting('updateEvent')">
                <i class="icons8-save"></i>Сохранить
              </span>
              <BeatLoader v-else
                          :loading="$wait.waiting('updateEvent')"
                          :color="'#ffffff'"
                          :size="'20px'">
              </BeatLoader>
            </button>
          </div>

          <form id="user-edit-form" autocomplete="off"
                @submit.stop.prevent="handleSubmitForm">

            <fieldset :disabled="$wait.waiting('updateEvent') || isEventDateExpired">

              <div class="form-group row" :class="{'has-error': errors.has('name')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Название</label>
                <div class="col">
                  <input v-model.trim="event.name"
                         v-validate="'required|max:191'"
                         data-vv-as="Название"
                         name="name"
                         type="text"
                         class="form-control">
                </div>
                <div v-show="errors.has('name')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('name') }}
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Тип</label>
                <div class="col input-group">
                  <multiselect v-model="event.event_type.data"
                               :options="eventTypes"
                               :selectLabel="'Нажмите для выбора'"
                               :selectedLabel="'Выбрано'"
                               :allow-empty="false"
                               deselect-label="Тип обязательный параметр"
                               placeholder="Выберите один вариант"
                               label="name"
                               track-by="name">
                    <div slot="noOptions">Типы мероприятия отсутствуют</div>
                    <div slot="noResult">Ничего не найдено</div>
                  </multiselect>
                </div>
              </div>

              <div class="form-group row" :class="{'has-error': errors.has('opens_from')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Начало</label>
                <div class="col input-group">
                  <flat-pickr v-model="event.opens_from"
                              :config="flatpickrConfig"
                              v-validate="'required'"
                              data-vv-as="Начало"
                              class="form-control"
                              title="Начало"
                              name="opens_from"
                              placeholder="Выберите дату">
                  </flat-pickr>
                </div>
                <div v-show="errors.has('opens_from')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('opens_from') }}
                </div>
              </div>

              <div class="form-group row" :class="{'has-error': errors.has('ends_to')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Окончание</label>
                <div class="col input-group">
                  <flat-pickr v-model="event.ends_to"
                              :config="flatpickrConfig"
                              v-validate="'required'"
                              data-vv-as="Окончание"
                              class="form-control"
                              title="Окончание"
                              name="ends_to"
                              placeholder="Выберите дату">
                  </flat-pickr>
                </div>
                <div v-show="errors.has('ends_to')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('ends_to') }}
                </div>
              </div>

              <div class="form-group row" :class="{'has-error': errors.has('description')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Описание</label>
                <div class="col">
                  <textarea v-model="event.description"
                            v-validate="'max:3000'"
                            data-vv-as="Описание"
                            rows="5"
                            name="description"
                            title="Описание мероприятия"
                            class="form-control">
                  </textarea>
                </div>
                <div v-show="errors.has('description')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('description') }}
                </div>
              </div>

            </fieldset>

            <button @click="deleteEvent"
                    v-if="!isEventDateExpired || $store.getters['auth/userRole'] === 'super'
                    || $store.getters['auth/userRole'] === 'admin'"
                    type="button" class="btn btn-outline-danger float-right">
              <i class="icons8-trash"></i>
              Удалить
            </button>

          </form>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { nullification } from '../../utils/batch'

  export default {
    name: 'EventEdit',

    metaInfo() {
      return {
        title: this.title,
      }
    },

    data() {
      return {
        title: '',
        event: {},
        eventName: '',
        eventTypes: [],
        isEventDateExpired: false,
        flatpickrConfig: {
          enableTime: true,
          disable: [
            {
              from: '1971-01-01',
              to: ( new Date() ).setDate( ( new Date() ).getDate() - 1 ),
            },
          ],
        },
      }
    },

    created() {
      this.$wait.start( 'findEventById' )

      this.$api.event.findEventById( this.$route.params.id, 'event_type' ).then( res => {
        if ( res.status === 200 && res.data.data ) {
          let event = res.data.data

          this.event = event
          this.eventName = event.name || ''
          this.title = 'Редактирование ' + this.eventName
          this.setIsEventDateExpired()
        }
      } ).finally( () => {
        this.$wait.end( 'findEventById' )
      } )

      this.$wait.start( 'findAllEventTypes' )

      this.$api.eventType.findAllEventTypes().then( res => {
        if ( res.status === 200 && res.data.data ) {
          this.eventTypes = res.data.data
        }
      } ).finally( () => {
        this.$wait.end( 'findAllEventTypes' )
      } )
    },

    beforeRouteEnter( to, from, next ) {
      if ( to.params.id.length !== parseInt( process.env.VUE_APP_HASH_ID_LENGTH ) ) {
        next( vm => {
          vm.$router.push( { name: 'NotFound' } )
        } )
      } else {
        return next()
      }
    },

    methods: {
      handleSubmitForm() {
        this.$validator.validateAll().then( validated => {
          if ( validated ) {
            let formData = nullification( {
              event_type_id: this.event.event_type.data ? this.event.event_type.data.id : null,
              name: this.event.name,
              description: this.event.description,
              opens_from: this.event.opens_from,
              ends_to: this.event.ends_to,
            } )

            this.$wait.start( 'updateEvent' )

            this.$api.event.updateEvent( this.event.id, formData, 'event_type' ).then( res => {
              if ( res.status === 200 && res.data.event.data ) {
                let event = res.data.event.data

                this.event = event
                this.eventName = event.name || ''
                this.title = 'Редактирование ' + this.eventName
                this.setIsEventDateExpired()

                this.$toasted.show( res.data.message, { type: 'success' } )
              }
            } ).finally( () => {
              this.$wait.end( 'updateEvent' )
            } )
          }
        } )
      },

      deleteEvent() {
        this.$modal.show( 'dialog', {
          title: 'Удалить мероприятие?',
          text: 'Это действие нельзя отменить.',
          buttons: [
            {
              title: 'Удалить',
              default: true,
              handler: async () => {
                this.$wait.start( 'deleteEvent' )

                let res = await this.$api.event.deleteEvent( this.$route.params.id )

                if ( res.status === 200 ) {
                  this.$modal.hide( 'dialog' )
                  this.$toasted.show( res.data.message, { type: 'success' } )
                  this.$router.push( '/events' )
                }

                this.$wait.end( 'deleteEvent' )
              },
            },
            {
              title: 'Отмена',
              handler: () => {
                this.$modal.hide( 'dialog' )
              },
            },
          ],
        } )
      },

      setIsEventDateExpired() {
        if ( Object.keys( this.event ).length > 0 ) {
          let nowTimestamp = Math.floor( ( new Date() ) / 1000 )
          let eventEndsTimestamp = ( new Date( this.event.ends_to || '' ) ).getTime() / 1000
          this.isEventDateExpired = nowTimestamp > eventEndsTimestamp
        }
      },
    },
  }
</script>
