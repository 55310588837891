// middleware
import auth from '../middleware/auth'
import authLogin from '../middleware/authLogin'
import checkRole from '../middleware/checkRole'

// base components
import Router from 'vue-router'
import Login from '../pages/Login.vue'
import Logout from '../pages/Logout.vue'
import NotFound from '../pages/NotFound.vue'

// route batches
import eventRoutes from './eventRoutes'
import scoreEventRoutes from './scoreEventRoutes'
import shopRoutes from './shopRoutes'
import userRoutes from './userRoutes'
import qrRoutes from './qrRoutes'
import cityRoutes from './cityRoutes'

const baseRoutes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      layout: 'blank',
      middleware: [authLogin],
    },
    component: Login,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      layout: 'blank',
      roles: ['*'],
      middleware: [auth, checkRole],
    },
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound,
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: '/404',
  },
]

const routes = baseRoutes.concat(
  eventRoutes,
  scoreEventRoutes,
  shopRoutes,
  userRoutes,
  qrRoutes,
  cityRoutes,
)

const router = new Router( {
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
} )

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory( context, middleware, index ) {
  const subsequentMiddleware = middleware[index]
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if ( !subsequentMiddleware ) return context.next

  return ( ...parameters ) => {
    // Run the default Vue Router `next()` callback first.
    context.next( ...parameters )
    // Than run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory( context, middleware, index + 1 )
    subsequentMiddleware( { ...context, next: nextMiddleware } )
  }
}

router.beforeEach( ( to, from, next ) => {
  if ( to.meta.middleware ) {
    const middleware = Array.isArray( to.meta.middleware )
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = { from, next, router, to }
    const nextMiddleware = nextFactory( context, middleware, 1 )

    return middleware[0]( { ...context, next: nextMiddleware } )
  }

  return next()
} )

export default router
