export default {
  settings: {
    windowSize: null,
  },
  registrationQR: {
    opened: false,
    selectedEvent: {},
    error: false,
  },
}
