import Vue from 'vue'
import api from './api/index'
import App from './App.vue'
import Router from 'vue-router'
import router from './router/index'
import store from './store/index'
import Meta from 'vue-meta'
import VueWait from 'vue-wait'
import LayoutBlank from './layouts/LayoutBlank'
import LayoutAdmin from './layouts/LayoutAdmin'
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'

Vue.component( 'layout-blank', LayoutBlank )
Vue.component( 'layout-admin', LayoutAdmin )
Vue.component( 'BeatLoader', BeatLoader )

Vue.use( Router )
Vue.use( VueWait )
Vue.use( Meta )

// other plugins
import './plugins/vueProgressBar'
import './plugins/veeValidate'
import './plugins/vueTables2'
import './plugins/vueMultiselect'
import './plugins/vueToasted'
import './plugins/vueFlatpickr'
import './plugins/vueJsModal'
import './plugins/vueOffline'
import './plugins/sentry'
import './utils/vueFilters'
import centrifuge from './plugins/centrifuge'
import { canAbility } from './utils/batch'

// config
Vue.config.productionTip = false

// custom properties
Vue.prototype.$api = api
Vue.prototype.$centrifuge = centrifuge
Vue.prototype.$can = canAbility

export default new Vue( {
  router,
  store,
  wait: new VueWait( {
    useVuex: true,
    registerComponent: true,     // Registers `v-wait` component
    componentName: 'v-wait',     // <v-wait> component name, you can set `my-loader` etc.
    registerDirective: true,     // Registers `v-wait` directive
    directiveName: 'wait',       // <span v-wait /> directive name, you can set `my-loader` etc.
  } ),
  render: h => h( App ),
} ).$mount( '#app' )
