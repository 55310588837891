import app from "../main";
import * as Sentry from "@sentry/vue";

Sentry.init({
  Vue: app,
  environment: process.env.NODE_ENV,
  autoSessionTracking: false,
  debug: process.env.VUE_APP_SENTRY_DEBUG === 'true',
  dsn: process.env.VUE_APP_SENTRY_DSN,
});
