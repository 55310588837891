<template>
  <div class="row">

    <div class="col px-0 px-lg-3">
      <div class="shadow rounded bg-white py-3 py-md-4 px-lg-2 mb-4">

        <div class="col d-flex flex-wrap align-items-center mb-3">
          <span class="h5 mr-3"><i class="nav-icon icons8-find-user-male"></i> {{ title }}</span>
          <router-link to="/users/add" class="btn btn-success mr-3">Добавить</router-link>
          <router-link to="/users/deleted"
                       class="btn btn-outline-danger">
            Удаленные</router-link>
        </div>

        <div class="col">
          <v-server-table :columns="columns" :options="tableOptions" :name="'user-list'">
            <router-link slot="surname"
                         slot-scope="props"
                         :to="userDetailLink( props.row.id )">
              {{ props.row.surname }}
            </router-link>
            <router-link :to="userEditLink( props.row.id )"
                         slot="action"
                         slot-scope="props"
                         class="btn btn-default py-0">
              <i class="icons8-edit"></i>
            </router-link>
          </v-server-table>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
  import isEmpty from 'lodash/isEmpty'

  export default {
    name: 'UserList',

    metaInfo() {
      return {
        title: this.title,
      }
    },

    data() {
      return {
        title: 'Пользователи',
        columnsSettings:
          [
            {
              name: 'Email',
              alias: 'email',
              sortable: true,
              filterable: true,
              visible: true,
            },
            {
              name: 'Фамилия',
              alias: 'surname',
              sortable: true,
              filterable: true,
              visible: true,
            },
            {
              name: 'Имя',
              alias: 'name',
              sortable: true,
              filterable: true,
              visible: true,
            },
            {
              name: 'Отчество',
              alias: 'patronymic',
              sortable: true,
              filterable: true,
              visible: true,
            },
            {
              name: 'Роль',
              alias: 'roles_name', // relationName_relationField
              sortable: false,
              filterable: true,
              visible: true,
            },
            {
              name: '',
              alias: 'action',
              sortable: false,
              filterable: false,
              visible: true,
            },
          ],
      }
    },

    computed: {
      tableOptions() {
        return {
          saveState: true,
          filterByColumn: true,
          skin: 'table table-bordered table-striped',
          filterable: this.filterableColumns,
          sortable: this.sortableColumns,
          headings: this.headings,
          perPage: 10,
          perPageValues: [10],
          pagination: { chunk: this.$store.getters.windowSize === 'large' ? 10 : 3 },
          columnsDropdown: false,
          columnsClasses: {
            id: 'mw-100',
          },
          listColumns: {
            roles_name: this.availableUserRoles(),
          },
          sortIcon: {
            base: 'icons8',
            up: 'icons8-chevron-up',
            down: 'icons8-chevron-down',
            is: 'icons8-up-and-down-arrows',
          },

          responseAdapter( resp ) {
            let data = this.getResponseData( resp )
            let users = data.data.data
            let total = data.data.meta ? data.data.meta.pagination.total : 0

            // vue-tables-2 dont support nested filtering and also needed in backend api
            for ( let user of users ) {
              if ( !isEmpty( user.roles ) ) {
                this.$set( user, 'roles_name', user.roles.data[0].name )
              }
            }

            // hotfix-user-list search bug

            /*if ( this.$store.getters['auth/userRole'] !== 'super' ) {
              users = users.filter( user => user.roles_name !== 'super' )
              total -= 1
            }

            if ( this.$store.getters['auth/userRole'] !== 'admin' ) {
              users = users.filter( user => user.roles_name !== 'admin' )
              total -= 1
            }*/

            // hotfix-user-list

            return {
              data: users,
              count: total,
            }
          },

          requestFunction( params ) {
            let options = {
              include: 'roles',
              params: params,
              paginate: 1,
            }

            return this.$api.user.findAllUsers( options )
          },
        }
      },

      filterableColumns() {
        return this.columnsSettings.filter( column => column.filterable ).map( column => column.alias )
      },

      sortableColumns() {
        return this.columnsSettings.filter( column => column.sortable ).map( column => column.alias )
      },

      headings() {
        let result = {}

        this.columnsSettings.forEach( column => {
          result[column.alias] = column.name
        } )

        return result
      },

      columns() {
        return this.columnsSettings.filter( column => column.visible ).map( column => column.alias )
      },
    },

    methods: {
      userEditLink( id ) {
        return `/users/${id}/edit`
      },

      userDetailLink( id ) {
        return `/users/${id}`
      },

      availableUserRoles() {
        return [
          {
            id: 'user',
            text: 'user',
          },
          {
            id: 'manager',
            text: 'manager',
          },
          {
            id: 'admin',
            text: 'admin',
          },
        ]
      },
    },
  }
</script>
