<template>
  <div class="cities">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'Cities',

    metaInfo() {
      return {
        title: 'Города',
      }
    },
  }
</script>
