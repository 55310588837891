<template>
  <div class="row">

    <div class="col col-xl-7 px-0 px-lg-3">
      <div class="card shadow">
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="card-title">
              {{ event.name }}
            </div>
            <button @click="handleSubmitForm" class="btn btn-success"
                    :disabled="$wait.waiting('createEvent')">
              <span v-if="!$wait.waiting('createEvent')">
                <i class="icons8-save"></i>Сохранить
              </span>
              <BeatLoader v-else
                          :loading="$wait.waiting('createEvent')"
                          :color="'#ffffff'"
                          :size="'20px'">
              </BeatLoader>
            </button>
          </div>

          <form id="user-edit-form" autocomplete="off"
                @submit.stop.prevent="handleSubmitForm">

            <fieldset :disabled="$wait.waiting('createEvent')">

              <div class="form-group row" :class="{'has-error': errors.has('name')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Название</label>
                <div class="col">
                  <input v-model.trim="event.name"
                         v-validate="'required|max:191'"
                         data-vv-as="Название"
                         name="name"
                         type="text"
                         class="form-control">
                </div>
                <div v-show="errors.has('name')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('name') }}
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Тип</label>
                <div class="col input-group">
                  <multiselect v-model="event.event_type"
                               :options="eventTypes"
                               :selectLabel="'Нажмите для выбора'"
                               :selectedLabel="'Выбрано'"
                               :allow-empty="false"
                               deselect-label="Тип обязательный параметр"
                               placeholder="Выберите один вариант"
                               label="name"
                               track-by="name">
                    <div slot="noOptions">Типы мероприятия отсутствуют</div>
                    <div slot="noResult">Ничего не найдено</div>
                  </multiselect>
                </div>
              </div>

              <div class="form-group row" :class="{'has-error': errors.has('opens_from')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Начало</label>
                <div class="col input-group">
                  <flat-pickr v-model="event.opens_from"
                              :config="flatpickrConfig"
                              v-validate="'required'"
                              data-vv-as="Начало"
                              class="form-control"
                              title="Начало"
                              name="opens_from"
                              placeholder="Выберите дату">
                  </flat-pickr>
                </div>
                <div v-show="errors.has('opens_from')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('opens_from') }}
                </div>
              </div>

              <div class="form-group row" :class="{'has-error': errors.has('ends_to')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Окончание</label>
                <div class="col input-group">
                  <flat-pickr v-model="event.ends_to"
                              :config="flatpickrConfig"
                              v-validate="'required'"
                              data-vv-as="Окончание"
                              class="form-control"
                              title="Окончание"
                              name="ends_to"
                              placeholder="Выберите дату">
                  </flat-pickr>
                </div>
                <div v-show="errors.has('ends_to')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('ends_to') }}
                </div>
              </div>

              <div class="form-group row" :class="{'has-error': errors.has('description')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Описание</label>
                <div class="col">
                  <textarea v-model="event.description"
                            v-validate="'max:3000'"
                            data-vv-as="Описание"
                            rows="5"
                            name="description"
                            title="Описание мероприятия"
                            class="form-control">
                  </textarea>
                </div>
                <div v-show="errors.has('description')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('description') }}
                </div>
              </div>

            </fieldset>

          </form>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { nullification } from '../../utils/batch'

  export default {
    name: 'EventAdd',

    metaInfo() {
      return {
        title: this.title,
      }
    },

    data() {
      return {
        title: 'Добавить мероприятие',
        event: {
          name: '',
          event_type: {},
          opens_from: '',
          ends_to: '',
          description: '',
        },
        eventTypes: [],
        flatpickrConfig: {
          enableTime: true,
          disable: [
            {
              from: '1971-01-01',
              to: (new Date()).setDate((new Date()).getDate() - 1)
            }
          ]
        }
      }
    },

    created() {
      this.$wait.start( 'findAllEventTypes' )

      this.$api.eventType.findAllEventTypes().then( res => {
        if ( res.status === 200 && res.data.data ) {
          this.eventTypes = res.data.data
        }
      } ).finally( () => {
        this.$wait.end( 'findAllEventTypes' )
      } )
    },

    methods: {
      handleSubmitForm() {
        this.$validator.validateAll().then( validated => {
          if ( validated ) {
            let formData = nullification( {
              event_type_id: this.event.event_type ? this.event.event_type.id : null,
              name: this.event.name,
              description: this.event.description,
              opens_from: this.event.opens_from,
              ends_to: this.event.ends_to,
            } )

            this.$wait.start( 'createEvent' )

            this.$api.event.createEvent( formData ).then( res => {
              if ( res.status === 201 && res.data.event.data ) {
                this.$toasted.show( res.data.message, {
                  type: 'success',
                  action: {
                    text: 'Изменить',
                    onClick: ( e, toastObject ) => {
                      let eventId = res.data.event.data.id
                      this.$router.push( `/events/${eventId}/edit` )
                    },
                  },
                } )
              }
            } ).finally( () => {
              this.$wait.end( 'createEvent' )
            } )
          }
        } )
      },
    },
  }
</script>
