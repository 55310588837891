import auth from '../middleware/auth'
import checkRole from '../middleware/checkRole'

import Users from '../pages/users/Users.vue'
import UserAdd from '../pages/users/UserAdd.vue'
import UserEdit from '../pages/users/UserEdit.vue'
import UserList from '../pages/users/UserList.vue'
import UserListTrashed from '../pages/users/UserListTrashed.vue'
import UserDetail from '../pages/users/UserDetail.vue'

export default [
  {
    path: '/users',
    meta: {
      roles: ['super', 'admin', 'manager'],
      middleware: [auth, checkRole],
    },
    component: Users,
    children: [
      {
        path: '',
        name: 'UserList',
        meta: {
          roles: ['super', 'admin', 'manager'],
          middleware: [auth, checkRole],
          crumbs: '/Пользователи',
        },
        component: UserList,
      },
      {
        path: 'deleted',
        name: 'UserListTrashed',
        meta: {
          roles: ['super', 'admin', 'manager'],
          middleware: [auth, checkRole],
          crumbs: '/Пользователи/Удаленные',
        },
        component: UserListTrashed,
      },
      {
        path: 'add',
        name: 'UserAdd',
        meta: {
          roles: ['super', 'admin', 'manager'],
          middleware: [auth, checkRole],
          crumbs: '/Пользователи/Добавить',
        },
        component: UserAdd,
      },
      {
        path: ':id',
        name: 'UserDetail',
        meta: {
          roles: ['*'],
          middleware: [auth, checkRole],
          crumbs: '/Пользователи/{:}',
        },
        component: UserDetail,
      },
      {
        path: ':id/edit',
        name: 'UserEdit',
        meta: {
          roles: ['*'],
          middleware: [auth, checkRole],
          crumbs: '/Пользователи/{:}/Изменить',
        },
        component: UserEdit,
      },
    ],
  },
]
