import auth from '../middleware/auth'
import checkRole from '../middleware/checkRole'
import { loadPage } from '../utils/batch'

export default [
  {
    path: '/score',
    meta: {
      roles: ['super', 'admin', 'manager'],
      middleware: [auth, checkRole],
    },
    component: loadPage( 'score/Score' ),
    children: [
      {
        path: '',
        name: 'ScoreEventList',
        meta: {
          roles: ['super', 'admin', 'manager'],
          middleware: [auth, checkRole],
          crumbs: '/Статистика',
        },
        component: loadPage( 'score/ScoreEventList' ),
      },
      {
        path: ':event_id',
        name: 'ScoreEventDetail',
        meta: {
          roles: ['super', 'admin', 'manager'],
          middleware: [auth, checkRole],
          crumbs: '/Статистика/{:}',
        },
        component: loadPage( 'score/ScoreEventDetail' ),
      },
    ],
  },

]
