<template>
  <div class="row" v-if="Object.keys( event ).length > 0">

    <div class="col-xl-6 px-0 px-lg-3">
      <div class="card shadow">
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center mb-4">
            <div class="card-title">
              <i class="icons8-event mr-2"></i>
              <span class="mr-3">{{ title }}</span>
            </div>
            <router-link :to="'/events/' + event.id + '/edit'" class="btn btn-outline-primary">
              Изменить
            </router-link>
          </div>
          <div class="form-group row">
            <label class="col-12 col-xl-4 col-form-label text-muted">Название</label>
            <div class="col-12 col-xl-8">
              <input :value="event.name" type="text" class="form-control-plaintext" readonly>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-xl-4 col-form-label text-muted">Начало</label>
            <div class="col-12 col-xl-8">
              <input :value="event.opens_from" type="text" class="form-control-plaintext" readonly>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-xl-4 col-form-label text-muted">Окончание</label>
            <div class="col-12 col-xl-8">
              <input :value="event.ends_to" type="text" class="form-control-plaintext" readonly>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-xl-4 col-form-label text-muted">Тип</label>
            <div class="col-12 col-xl-8">
              <input :value="event.event_type.data ? event.event_type.data.name : ''"
                     class="form-control-plaintext" readonly>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-xl-4 col-form-label text-muted">Описание</label>
            <div class="col-12 col-xl-8">
              <textarea :value="event.description" class="form-control-plaintext"
                        rows="3" title="Описание мероприятия" readonly></textarea>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-xl-4 col-form-label text-muted">Добавлено</label>
            <div class="col-12 col-xl-8">
              <input :value="event.created_at + ' (' + event.readable_created_at + ')'"
                     type="text" class="form-control-plaintext" readonly>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-xl-4 col-form-label text-muted">Обновлено</label>
            <div class="col-12 col-xl-8">
              <input :value="event.updated_at + ' (' + event.readable_updated_at + ')'"
                     type="text" class="form-control-plaintext" readonly>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    name: 'EventDetail',

    metaInfo() {
      return {
        title: this.title,
      }
    },

    data() {
      return {
        title: '',
        event: {},
      }
    },

    created() {
      this.$wait.start( 'findEventById' )

      this.$api.event.findEventById( this.$route.params.id, 'event_type' ).then( res => {
        if ( res.status === 200 && res.data.data ) {
          let event = res.data.data

          this.event = event
          this.title = event.name || ''
        }
      } ).finally( () => {
        this.$wait.end( 'findEventById' )
      } )
    },

    beforeRouteEnter( to, from, next ) {
      if ( to.params.id.length !== parseInt( process.env.VUE_APP_HASH_ID_LENGTH ) ) {
        next( vm => {
          vm.$router.push( { name: 'NotFound' } )
        } )
      } else {
        return next()
      }
    },
  }
</script>
