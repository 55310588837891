<template>
  <div class="row">

    <div class="col col-xl-7 px-0 px-lg-3">
      <div class="card shadow">
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="card-title">
              {{ shop.name || shop.code }}
            </div>
            <button @click="handleSubmitForm" class="btn btn-success mr-3"
                    :disabled="$wait.waiting('createShop')">
              <span v-if="!$wait.waiting('createShop')">
                <i class="icons8-save"></i>Сохранить
              </span>
              <BeatLoader v-else
                          :loading="$wait.waiting('createShop')"
                          :color="'#ffffff'"
                          :size="'20px'">
              </BeatLoader>
            </button>
          </div>

          <form id="user-edit-form" autocomplete="off"
                @submit.stop.prevent="handleSubmitForm">

            <fieldset :disabled="$wait.waiting('createShop')">

              <div class="form-group row" :class="{'has-error': errors.has('name')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Название</label>
                <div class="col">
                  <input v-model.trim="shop.name"
                         v-validate="'max:191'"
                         data-vv-as="Название"
                         name="name"
                         type="text"
                         class="form-control">
                </div>
                <div v-show="errors.has('name')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('name') }}
                </div>
              </div>

              <div class="form-group row" :class="{'has-error': errors.has('code')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Код</label>
                <div class="col">
                  <input v-model.trim="shop.code"
                         v-validate="'required|max:191'"
                         data-vv-as="Код"
                         name="code"
                         type="text"
                         class="form-control">
                </div>
                <div v-show="errors.has('code')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('code') }}
                </div>
              </div>

              <div class="form-group row" :class="{'has-error': errors.has('address')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Адрес</label>
                <div class="col">
                  <input v-model.trim="shop.address"
                         v-validate="'max:191'"
                         data-vv-as="Адрес"
                         name="address"
                         type="text"
                         class="form-control">
                </div>
                <div v-show="errors.has('address')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('address') }}
                </div>
              </div>

              <div class="form-group row" :class="{'has-error': errors.has('city')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Город</label>
                <div class="col">
                  <multiselect v-model="shop.city"
                               :options="cities"
                               :loading="$wait.waiting('asyncFindCity')"
                               :internal-search="false"
                               :clear-on-select="true"
                               :close-on-select="true"
                               :selectLabel="'Нажмите для выбора'"
                               :selectedLabel="'Выбрано'"
                               :deselectLabel="'Нажмите, чтобы удалить'"
                               :tagPlaceholder="'Введите город'"
                               label="name"
                               track-by="name"
                               @search-change="asyncFindCity"
                               placeholder="Начните писать город...">
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">{{ props.option.name }}</span>
                        <br>
                        <span class="option__small text-muted">
                          ({{ props.option.region.data.name }})
                        </span>
                      </div>
                    </template>
                    <div slot="noOptions">Список пуст</div>
                    <span slot="noResult">Не найден ни один город с таким названием</span>
                  </multiselect>
                </div>
                <div v-show="errors.has('city')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('city') }}
                </div>
              </div>

              <div class="form-group row" :class="{'has-error': errors.has('description')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Описание</label>
                <div class="col">
                  <textarea v-model="shop.description"
                            v-validate="'max:3000'"
                            data-vv-as="Описание"
                            name="description"
                            rows="5"
                            title="Описание магазина"
                            class="form-control">
                  </textarea>
                </div>
                <div v-show="errors.has('description')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('description') }}
                </div>
              </div>

            </fieldset>

          </form>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import debounce from 'lodash/debounce'
  import { nullification } from '../../utils/batch'

  export default {
    name: 'ShopAdd',

    metaInfo() {
      return {
        title: this.title,
      }
    },

    data() {
      return {
        title: 'Добавить магазин',
        shop: {
          name: '',
          address: '',
          code: '',
          description: '',
          city: {},
        },
        cities: [],
      }
    },

    methods: {
      handleSubmitForm() {
        this.$validator.validateAll().then( validated => {
          if ( validated ) {
            let formData = nullification( {
              city_id: this.shop.city ? this.shop.city.id : null,
              name: this.shop.name,
              address: this.shop.address,
              code: this.shop.code,
              description: this.shop.description,
            } )

            this.$wait.start( 'createShop' )

            this.$api.shop.createShop( formData ).then( res => {
              if ( res.status === 201 && res.data.shop.data ) {
                this.$toasted.show( res.data.message, {
                  type: 'success',
                  action: {
                    text: 'Изменить',
                    onClick: ( e, toastObject ) => {
                      let shopId = res.data.shop.data.id
                      this.$router.push( `/shops/${shopId}/edit` )
                    },
                  },
                } )
              }
            } ).finally( () => {
              this.$wait.end( 'createShop' )
            } )
          }
        } )
      },

      asyncFindCity:
        debounce( function ( query ) {
          this.$wait.start( 'asyncFindCity' )

          if ( query.length > 1 ) {
            this.$api.city.findCityByName( query, 'region' ).then( res => {
              this.cities = res.data.data
            } ).finally( () => {
              this.$wait.end( 'asyncFindCity' )
            } )
          } else {
            this.$wait.end( 'asyncFindCity' )
          }
        }, 200 ),
    },
  }
</script>
