import Vue from 'vue'

Vue.filter( 'shortness', ( value, length ) => {
  if ( !value ) {
    return ''
  }

  value = value.toString()

  return value.length > length ? value.slice( 0, length - 1 ) + '...' : value
} )
