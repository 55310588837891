import Vue from 'vue'
import { ClientTable, ServerTable } from 'vue-tables-2'
//import template from 'vue-tables-2/compiled/templates/bootstrap4/template'

const config = {
  texts: {
    count: 'Показано {from} - {to} из {count} записей|{count} записей|Одна запись',
    first: 'Первая',
    last: 'Последняя',
    filter: 'Фильтр:',
    filterPlaceholder: '',
    limit: 'Записей:',
    page: 'Страница:',
    noResults: 'Нет данных для отображения',
    filterBy: '',
    loading: 'Загрузка...',
    defaultOption: '...',
    columns: 'Колонки',
  },
}

Vue.use( ClientTable, config, false, 'bootstrap4', )

Vue.use( ServerTable, config, false, 'bootstrap4', )

/*
Vue.use(ClientTable, [options = {}], [useVuex = false], [theme = 'bootstrap3'], [template = 'default']);
*/
