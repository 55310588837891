<template>
  <div class="c-not-found">
    <div class="c-not-found__wrapper d-flex flex-column justify-content-center align-items-center mb-5">
      <div class="c-not-found__title">
        404
      </div>
      <div class="c-not-found__content">
        <p>Страница не найдена.
          <img src="@/assets/img/smiles/icons8-sleep-emoji.png" title="Приснул" />
        </p>
        <button @click="$router.go(-2)"
                class="btn btn-primary btn-lg"
                title="Назад"
        >Назад
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NotFound',

    metaInfo() {
      return {
        title: '404 Не найдено',
      }
    },
  }
</script>

<style lang="scss">
  .c-not-found {
    height: calc(100vh - 60px);
  }

  .c-not-found__wrapper {
    height: inherit;
  }

  .c-not-found__title {
    font-size: 15rem;
    text-shadow: -2px -2px 0px $primary, 3px 3px 0px #ff005a;
    letter-spacing: 1rem;
    text-transform: uppercase;
    color: $white;

    @include media-breakpoint-down(md) {
      font-size: 6rem;
    }
  }

  .c-not-found__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    font-size: $h3-font-size;

    @include media-breakpoint-down(md) {
      font-size: $h5-font-size;
    }
  }
</style>
