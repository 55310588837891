import auth from '../middleware/auth'
import checkRole from '../middleware/checkRole'

import Shops from '../pages/shops/Shops.vue'
import ShopAdd from '../pages/shops/ShopAdd.vue'
import ShopEdit from '../pages/shops/ShopEdit.vue'
import ShopList from '../pages/shops/ShopList.vue'
import ShopDetail from '../pages/shops/ShopDetail.vue'

export default [
  {
    path: '/shops',
    meta: {
      roles: ['super', 'admin', 'manager'],
      middleware: [auth, checkRole],
    },
    component: Shops,
    children: [
      {
        path: '',
        name: 'ShopList',
        meta: {
          roles: ['super', 'admin', 'manager'],
          middleware: [auth, checkRole],
          crumbs: '/Магазины',
        },
        component: ShopList,
      },
      {
        path: 'add',
        name: 'ShopAdd',
        meta: {
          roles: ['super', 'admin', 'manager'],
          middleware: [auth, checkRole],
          crumbs: '/Магазины/Добавить',
        },
        component: ShopAdd,
      },
      {
        path: ':id',
        name: 'ShopDetail',
        meta: {
          roles: ['super', 'admin', 'manager'],
          middleware: [auth, checkRole],
          crumbs: '/Магазины/{:}',
        },
        component: ShopDetail,
      },
      {
        path: ':id/edit',
        name: 'ShopEdit',
        meta: {
          roles: ['super', 'admin', 'manager'],
          middleware: [auth, checkRole],
          crumbs: '/Магазины/{:}/Изменить',
        },
        component: ShopEdit,
      },
    ],
  },
]
