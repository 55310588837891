<template>
  <div class="row">

    <div class="col col-xl-7 px-0 px-lg-3">
      <div class="card shadow">
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="card-title">
              {{ fullName }}
            </div>
            <button @click="handleSubmitForm" class="btn btn-success mr-3"
                    :disabled="$wait.waiting('createUser')">
                <span v-if="!$wait.waiting('createUser')">
                  <i class="icons8-save"></i>Сохранить
                </span>
              <BeatLoader v-else
                          :loading="$wait.waiting('createUser')"
                          :color="'#ffffff'"
                          :size="'20px'">
              </BeatLoader>
            </button>
          </div>

          <form id="user-edit-form" autocomplete="off"
                @submit.stop.prevent="handleSubmitForm">

            <fieldset :disabled="$wait.waiting('createUser')">

              <div class="form-group row" :class="{'has-error': errors.has('email')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Email</label>
                <div class="col">
                  <input v-model.trim="user.email"
                         v-validate="'required|email|max:191'"
                         data-vv-as="Email"
                         name="email"
                         type="text"
                         class="form-control">
                </div>
                <div v-show="errors.has('email')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('email') }}
                </div>
              </div>

              <div class="form-group row" :class="{'has-error': errors.has('password')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Пароль</label>
                <div class="col input-group">
                  <input v-model="user.password"
                         v-validate="'required|min:6'"
                         data-vv-as="Пароль"
                         name="password"
                         class="form-control"
                         readonly>
                  <div class="input-group-append">
                    <div @click="getGeneratedPassword()"
                         class="btn btn-info input-group-addon">
                      <div v-show="!$wait.waiting('getGeneratedPassword')">
                        <i class="icons8-refresh"></i>
                        Обновить
                      </div>
                      <BeatLoader v-show="$wait.waiting('getGeneratedPassword')"
                                  :loading="$wait.waiting('getGeneratedPassword')"
                                  :color="'#ffffff'"
                                  :size="'20px'">
                      </BeatLoader>
                    </div>
                  </div>
                </div>
                <div v-show="errors.has('password')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('password') }}
                </div>
              </div>

              <div class="form-group row" :class="{'has-error': errors.has('surname')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Фамилия</label>
                <div class="col">
                  <input v-model.trim="user.surname"
                         v-validate="'required|max:30'"
                         data-vv-as="Фамилия"
                         name="surname"
                         type="text"
                         class="form-control">
                </div>
                <div v-show="errors.has('surname')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('surname') }}
                </div>
              </div>

              <div class="form-group row" :class="{'has-error': errors.has('name')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Имя</label>
                <div class="col">
                  <input v-model.trim="user.name"
                         v-validate="'required|max:30'"
                         data-vv-as="Имя"
                         name="name"
                         type="text"
                         class="form-control">
                </div>
                <div v-show="errors.has('name')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('name') }}
                </div>
              </div>

              <div class="form-group row" :class="{'has-error': errors.has('patronymic')}">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Отчество</label>
                <div class="col">
                  <input v-model.trim="user.patronymic"
                         v-validate="'max:30'"
                         data-vv-as="Отчество"
                         name="patronymic"
                         type="text"
                         class="form-control">
                </div>
                <div v-show="errors.has('patronymic')"
                     class="col-12 offset-xl-3 invalid-feedback">{{ errors.first('patronymic') }}
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Магазин</label>
                <div class="col input-group">
                  <multiselect v-model="user.shop"
                               :options="shops"
                               :selectLabel="'Нажмите для выбора'"
                               :selectedLabel="'Выбрано'"
                               :deselectLabel="'Нажмите, чтобы удалить'"
                               placeholder="Выберите один вариант"
                               label="name"
                               track-by="name">
                    <div slot="noOptions">Магазины отсутствуют</div>
                    <div slot="noResult">Ничего не найдено</div>
                  </multiselect>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Роль</label>
                <div class="col input-group">
                  <multiselect v-model="user.role"
                               :options="roles"
                               :selectLabel="'Нажмите для выбора'"
                               :selectedLabel="'Выбрано'"
                               :allow-empty="false"
                               name="role"
                               deselect-label="Роль обязательный параметр"
                               placeholder="Выберите один вариант"
                               label="name"
                               track-by="name">
                  </multiselect>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-3 col-form-label text-muted text-xl-right">Статус</label>
                <div class="col input-group">
                  <select v-model="user.status" class="form-control">
                    <option :value="3">Активен</option>
                    <option :value="5">Заблокирован</option>
                  </select>
                </div>
              </div>

            </fieldset>

          </form>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { nullification } from '../../utils/batch'

  export default {
    name: 'UserAdd',

    metaInfo() {
      return {
        title: this.title,
      }
    },

    data() {
      return {
        title: 'Добавить пользователя',
        user: {
          password: '',
          email: '',
          name: '',
          surname: '',
          patronymic: '',
          status: 3,
          shop: {},
          role: {},
        },
        shops: [],
        roles: [],
      }
    },

    computed: {
      fullName() {
        return `${this.user.name} ${this.user.surname} ${this.user.patronymic}`
      },
    },

    created() {
      this.$wait.start( 'user findAllRoles' )

      this.$api.role.findAllRoles().then( res => {
        if ( res.status === 200 && res.data.data ) {
          this.roles = res.data.data
          this.user.role = res.data.data.filter( role => {
            return role.name === 'user' // default role
          } )[0]
        }
      } ).finally( () => {
        this.$wait.end( 'user findAllRoles' )
      } )

      this.$wait.start( 'shop findAllShops' )

      this.$api.shop.findAllShops().then( res => {
        if ( res.status === 200 && res.data.data ) {
          this.shops = res.data.data
        }
      } ).finally( () => {
        this.$wait.end( 'shop findAllShops' )
      } )
    },

    methods: {
      handleSubmitForm() {
        this.$validator.validateAll().then( validated => {
          if ( validated ) {
            let formData = nullification( {
              shop_id: this.user.shop ? this.user.shop.id : null,
              role_id: this.user.role.id,
              email: this.user.email,
              name: this.user.name,
              surname: this.user.surname,
              patronymic: this.user.patronymic,
              password: this.user.password,
              status: this.user.status,
            } )

            this.$wait.start( 'createUser' )

            this.$api.user.createUser( formData ).then( res => {
              if ( res.status === 201 && res.data.user.data ) {
                this.$toasted.show( res.data.message, {
                  type: 'success',
                  action: {
                    text: 'Измени меня, если хочешь',
                    onClick: ( e, toastObject ) => {
                      // toastObject.goAway(0);
                      let userId = res.data.user.data.id
                      this.$router.push( `/users/${userId}/edit` )
                    },
                  },
                } )
              }
            } ).finally( () => {
              this.$wait.end( 'createUser' )
            } )
          }
        } )
      },

      async getGeneratedPassword() {
        this.$wait.start( 'getGeneratedPassword' )

        let res = await this.$api.user.generatePassword()

        this.$set( this.user, 'password', res.data )

        this.$wait.end( 'getGeneratedPassword' )
      },
    },
  }
</script>
