import auth from '../middleware/auth'
import checkRole from '../middleware/checkRole'

import Events from '../pages/events/Events.vue'
import EventAdd from '../pages/events/EventAdd.vue'
import EventEdit from '../pages/events/EventEdit.vue'
import EventList from '../pages/events/EventList.vue'
import EventDetail from '../pages/events/EventDetail.vue'

export default [
  {
    path: '/events',
    meta: {
      roles: ['super', 'admin', 'manager'],
      middleware: [auth, checkRole],
    },
    component: Events,
    children: [
      {
        path: '',
        name: 'EventList',
        meta: {
          roles: ['super', 'admin', 'manager'],
          middleware: [auth, checkRole],
          crumbs: '/Мероприятия',
        },
        component: EventList,
      },
      {
        path: 'add',
        name: 'EventAdd',
        meta: {
          roles: ['super', 'admin', 'manager'],
          middleware: [auth, checkRole],
          crumbs: '/Мероприятия/Добавить',
        },
        component: EventAdd,
      },
      {
        path: ':id',
        name: 'EventDetail',
        meta: {
          roles: ['super', 'admin', 'manager'],
          middleware: [auth, checkRole],
          crumbs: '/Мероприятия/{:}',
        },
        component: EventDetail,
      },
      {
        path: ':id/edit',
        name: 'EventEdit',
        meta: {
          roles: ['super', 'admin', 'manager'],
          middleware: [auth, checkRole],
          crumbs: '/Мероприятия/{:}/Изменить',
        },
        component: EventEdit,
      },
    ],
  },
]
