import Vue from '../main'
import findIndex from 'lodash/findIndex'

export default {
  SET_WINDOW_SIZE( state, windowSize ) {
    state.settings.windowSize = windowSize
  },

  SET_REGISTRATION_QR_SELECTED_EVENT( state, object ) {
    state.registrationQR.selectedEvent = object
  },

  SET_REGISTRATION_QR_OPENED( state, bool ) {
    state.registrationQR.opened = bool
  },

  SET_REGISTRATION_QR_ERROR( state, bool ) {
    state.registrationQR.error = bool
  },

  // example
  UPDATE_USER_OBJECT( state, object ) {
    let index = findIndex( state.users, ['id', object.id] )
    Vue.$set( state.users, index, object )
  },
}
