import Vue from 'vue'
import VueProgressBar from 'vue-progressbar'

const config = {
  color: 'rgb(145, 255, 200)',
  failedColor: 'red',
  height: '2px',
}

Vue.use( VueProgressBar, config )
