import store from '../store/index'

export default function checkRole( { next, router, to } ) {
  if ( to.meta.roles.includes( '*' ) ) {
    return next()
  } else if ( !to.meta.roles.includes( store.getters['auth/userRole'] ) ) {
    return router.push( { name: 'NotFound' } )
  } else {
    return next()
  }
}
