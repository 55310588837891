<template>
  <div class="l-fullscreen">
    <div class="c-login d-flex justify-content-center align-items-center">
      <div class="col-xl-4 col-lg-5 col-md-6">

        <div class="d-flex justify-content-center align-items-center mb-5">
          <img src="@/assets/logo.png" alt="FinalScore" class="mw-100">
        </div>

        <form @submit.stop.prevent="submitLoginForm('login-form')"
              data-vv-scope="login-form">

          <fieldset :disabled="$wait.waiting('loginToApp')">

            <div class="form-group">
              <label>Email</label>
              <input v-model="email"
                     name="email"
                     v-validate.disable="'required|email'"
                     ref="inputEmail"
                     class="form-control input"
                     data-vv-as="Email"
                     title="Пароль"
                     type="email">

              <div v-show="errors.has('login-form.email')"
                   class="invalid-feedback">{{ errors.first('login-form.email') }}
              </div>
            </div>

            <div class="form-group">
              <label>Пароль</label>
              <input v-model="password"
                     name="password"
                     v-validate.disable="'required|min:6'"
                     class="form-control input"
                     data-vv-as="Пароль"
                     type="password"
                     title="Пароль">

              <div v-show="errors.has('login-form.password')"
                   class="invalid-feedback">{{ errors.first('login-form.password') }}
              </div>
            </div>

          </fieldset>

          <button class="btn btn-lg btn-block btn-primary b-radius-2 mt-4"
                  :disabled="$wait.waiting('loginToApp')"
                  type="submit">
            <span v-if="!$wait.waiting('loginToApp')">Войти</span>
            <BeatLoader v-else
                        :loading="$wait.waiting('loginToApp')"
                        :color="'#ffffff'"
                        :size="'24px'">
            </BeatLoader>
          </button>

          <div v-show="error" class="alert alert-danger mt-3" role="alert">
            {{ error }}
          </div>

        </form>

      </div>
    </div>
  </div>
</template>

<script>
  import { captureException as SentryException } from '@sentry/vue'
  import { setAuthDataToStorage } from '../utils/batch'
  import BeatLoader from 'vue-spinner/src/BeatLoader.vue'

  export default {
    name: 'Login',

    components: {
      BeatLoader,
    },

    metaInfo() {
      return {
        title: 'Вход',
      }
    },

    data() {
      return {
        email: null,
        password: null,
        error: null,
      }
    },

    mounted() {
      this.$refs.inputEmail.focus()
    },

    methods: {
      submitLoginForm( scope ) {
        this.$validator.validateAll( scope ).then( validated => {
          if ( validated ) {
            let formData = {
              email: this.email,
              password: this.password,
            }

            this.$wait.start( 'loginToApp' )

            this.$api.auth.login( formData ).then( res => {
              if ( res.status === 200 ) {
                setAuthDataToStorage( res.data.access_token, res.data.refresh_token, res.data.expires_in )

                return this.$store.dispatch( 'auth/fetchUser' )
              }

              return false
            } ).then( res => {
              if ( res && res.status === 200 && this.$store.getters['auth/isAuth'] ) {
                if ( this.$store.getters['auth/userRole'] === 'user' ) {
                  this.$router.push( { name: 'UserDetail', params: { id: this.$store.getters['auth/userId'] } } )
                } else {
                  this.$router.push( { name: 'ScoreEventList' } )
                }

                this.clearLoginForm( scope )
              }
            } ).catch( e => {
              SentryException( e )
            } ).finally( () => {
              this.$wait.end( 'loginToApp' )
            } )
          }
        } )
      },

      clearLoginForm( scope ) {
        this.$validator.errors.clear( scope )
        this.email = this.password = null
      },
    },
  }
</script>

<style lang="scss">
  .c-login {
    height: inherit;
  }

  .v-spinner {
    display: flex;
    justify-content: center;
  }
</style>
