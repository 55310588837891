import Vue from 'vue'
import Toasted from 'vue-toasted'

const matches = window.matchMedia( '(min-width: 992px)' ).matches

const options = {
  position: 'top-right',
  duration: 5000,
  theme: 'outline',
  fullWidth: !matches,
  fitToScreen: !matches,
}

Vue.use( Toasted, options )
