import auth from '../middleware/auth'
import checkRole from '../middleware/checkRole'
import { loadPage } from '../utils/batch'

import Cities from '../pages/cities/Cities.vue'

export default [
  {
    path: '/cities',
    meta: {
      roles: ['super', 'admin', 'manager'],
      middleware: [auth, checkRole],
    },
    component: Cities,
    children: [
      {
        path: '',
        name: 'CityList',
        meta: {
          roles: ['super', 'admin', 'manager'],
          middleware: [auth, checkRole],
          crumbs: '/Города',
        },
        component: loadPage( 'cities/CityList' ),
      },
      {
        path: 'add',
        name: 'CityAdd',
        meta: {
          roles: ['super', 'admin', 'manager'],
          middleware: [auth, checkRole],
          crumbs: '/Города/Добавить',
        },
        component: loadPage( 'cities/CityAdd' ),
      },
      {
        path: ':id',
        name: 'CityDetail',
        meta: {
          roles: ['super', 'admin', 'manager'],
          middleware: [auth, checkRole],
          crumbs: '/Города/{:}',
        },
        component: loadPage( 'cities/CityDetail' ),
      },
      {
        path: ':id/edit',
        name: 'CityEdit',
        meta: {
          roles: ['super', 'admin', 'manager'],
          middleware: [auth, checkRole],
          crumbs: '/Города/{:}/Изменить',
        },
        component: loadPage( 'cities/CityEdit' ),
      },
    ],
  },
]
