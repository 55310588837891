import api from '../api/index'
import Centrifuge from 'centrifuge'

const centrifuge = new Centrifuge( process.env.VUE_APP_CENTRIFUGE_WEBSOCKET_URL, {
  debug: process.env.VUE_APP_CENTRIFUGE_DEBUG === 'true',
  refreshEndpoint: process.env.VUE_APP_API_URL + '/centrifuge/token',
  refreshInterval: 3000,
  refreshHeaders: {
    'Authorization': 'Bearer ' + localStorage.getItem( 'access_token' ),
  },
  onRefresh: function (ctx, cb) {
    api.centrifuge.token().then((res) => {
      let data = {
        status: 200,
        data: {
          token: res.data.access_token,
        },
      };

      cb(data);
    });
  },
  refreshAttempts: 5,
  onRefreshFailed: () => {
    this.$toasted.show( 'Ошибка Центрифуги. Обратитесь к администрации.', { type: 'error' } )
  },
} )

export default centrifuge
