<template>
  <div class="row" v-if="Object.keys( shop ).length > 0">

    <div class="col-xl-6 px-0 px-lg-3">
      <div class="card shadow">
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center mb-4">
            <div class="card-title">
              <i class="icons8-market mr-2"></i>
              <span class="mr-3">{{ title }}</span>
            </div>
            <router-link :to="'/shops/' + shop.id + '/edit'" class="btn btn-outline-primary">
              Изменить
            </router-link>
          </div>
          <div class="form-group row">
            <label class="col-12 col-xl-4 col-form-label text-muted">Название</label>
            <div class="col-12 col-xl-8">
              <input :value="shop.name" type="text" class="form-control-plaintext" readonly>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-xl-4 col-form-label text-muted">Код</label>
            <div class="col-12 col-xl-8">
              <input :value="shop.code" type="text" class="form-control-plaintext" readonly>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-xl-4 col-form-label text-muted">Адрес</label>
            <div class="col-12 col-xl-8">
              <input :value="shop.address" type="text" class="form-control-plaintext" readonly>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-xl-4 col-form-label text-muted">Город</label>
            <div class="col-12 col-xl-8">
              <input :value="shop.city.data ? shop.city.data.name : ''"
                     class="form-control-plaintext" readonly>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-xl-4 col-form-label text-muted">Описание</label>
            <div class="col-12 col-xl-8">
              <textarea :value="shop.description" class="form-control-plaintext"
                        rows="3" title="Описание магазина" readonly></textarea>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-xl-4 col-form-label text-muted">Добавлен</label>
            <div class="col-12 col-xl-8">
              <input :value="shop.created_at + ' (' + shop.readable_created_at + ')'"
                     type="text" class="form-control-plaintext" readonly>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-xl-4 col-form-label text-muted">Обновлен</label>
            <div class="col-12 col-xl-8">
              <input :value="shop.updated_at + ' (' + shop.readable_updated_at + ')'"
                     type="text" class="form-control-plaintext" readonly>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'ShopDetail',

    metaInfo() {
      return {
        title: this.title,
      }
    },

    data() {
      return {
        title: '',
        shop: {},
      }
    },

    created() {
      this.$wait.start( 'findShopById' )

      this.$api.shop.findShopById( this.$route.params.id, 'city' ).then( res => {
        if ( res.status === 200 && res.data.data ) {
          let shop = res.data.data

          this.shop = shop
          this.title = shop.name || shop.code
        }
      } ).finally( () => {
        this.$wait.end( 'findShopById' )
      } )
    },

    beforeRouteEnter( to, from, next ) {
      if ( to.params.id.length !== parseInt( process.env.VUE_APP_HASH_ID_LENGTH ) ) {
        next( vm => {
          vm.$router.push( { name: 'NotFound' } )
        } )
      } else {
        return next()
      }
    },
  }
</script>
