<template>
  <div class="row">

    <div class="col px-0 px-lg-3">
      <div class="shadow rounded bg-white py-3 py-md-4 px-lg-2 mb-4">

        <div class="col d-flex flex-wrap align-items-center mb-3">
          <span class="h5 mr-3"><i class="nav-icon icons8-event"></i> {{ title }}</span>
          <router-link to="/events/add" class="btn btn-success">Добавить</router-link>
        </div>

        <div class="col">
          <v-server-table :columns="columns" :options="tableOptions" :name="'event-list'">
            <router-link slot="name"
                         slot-scope="props"
                         :to="eventDetailLink( props.row.id )">
              {{ props.row.name }}
            </router-link>
            <router-link :to="eventEditLink( props.row.id )"
                         slot="action"
                         slot-scope="props"
                         class="btn btn-default py-0">
              <i class="icons8-edit"></i>
            </router-link>
          </v-server-table>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'EventList',

    metaInfo() {
      return {
        title: this.title,
      }
    },

    data() {
      return {
        title: 'Мероприятия',
        eventTypes: [],
        columnsSettings:
          [
            {
              name: 'Название',
              alias: 'name',
              sortable: true,
              filterable: true,
              visible: true,
            },
            {
              name: 'Тип',
              alias: 'eventType_name', // relationName_relationField
              sortable: false,
              filterable: true,
              visible: true,
            },
            {
              name: 'Начало',
              alias: 'opens_from',
              sortable: true,
              filterable: true,
              visible: true,
            },
            {
              name: 'Окончание',
              alias: 'ends_to',
              sortable: true,
              filterable: true,
              visible: true,
            },
            {
              name: 'Описание',
              alias: 'description',
              sortable: true,
              filterable: true,
              visible: true,
            },
            {
              name: 'Добавлено',
              alias: 'created_at',
              sortable: true,
              filterable: false,
              visible: true,
            },
            {
              name: '',
              alias: 'action',
              sortable: false,
              filterable: false,
              visible: true,
            },
          ],
      }
    },

    computed: {
      tableOptions() {
        return {
          saveState: true,
          filterByColumn: true,
          skin: 'table table-bordered table-striped',
          filterable: this.filterableColumns,
          sortable: this.sortableColumns,
          headings: this.headings,
          perPage: 10,
          perPageValues: [10],
          pagination: { chunk: this.$store.getters.windowSize === 'large' ? 10 : 3 },
          columnsDropdown: false,
          orderBy: {
            column: 'opens_from',
            ascending: false,
          },
          columnsClasses: {
            id: 'mw-100',
          },
          listColumns: {
            eventType_name: this.eventTypes,
          },
          sortIcon: {
            base: 'icons8',
            up: 'icons8-chevron-up',
            down: 'icons8-chevron-down',
            is: 'icons8-up-and-down-arrows',
          },

          responseAdapter( resp ) {
            let data = this.getResponseData( resp )
            let events = data.data.data

            // vue-tables-2 dont support nested filtering and also needed in backend api
            events.forEach( event => {
              if ( event.event_type.data ) {
                this.$set( event, 'eventType_name', event.event_type.data.name )
              }
            } )

            return {
              data: events,
              count: data.data.meta ? data.data.meta.pagination.total : null,
            }
          },

          requestFunction( params ) {
            let options = {
              include: 'event_type',
              params: params,
              paginate: 1,
            }

            return this.$api.event.findAllEvents( options )
          },
        }
      },

      filterableColumns() {
        return this.columnsSettings.filter( column => column.filterable ).map( column => column.alias )
      },

      sortableColumns() {
        return this.columnsSettings.filter( column => column.sortable ).map( column => column.alias )
      },

      headings() {
        let result = {}

        this.columnsSettings.forEach( column => {
          result[column.alias] = column.name
        } )

        return result
      },

      columns() {
        return this.columnsSettings.filter( column => column.visible ).map( column => column.alias )
      },
    },

    created() {
      this.$wait.start( 'findAllEventTypes' )

      this.$api.eventType.findAllEventTypes().then( res => {
        if ( res.status === 200 && res.data.data ) {
          this.eventTypes = res.data.data.map( item => ( { id: item.name, text: item.name } ) )
        }
      } ).finally( () => {
        this.$wait.end( 'findAllEventTypes' )
      } )
    },

    methods: {
      eventEditLink( id ) {
        return `/events/${id}/edit`
      },

      eventDetailLink( id ) {
        return `/events/${id}`
      },
    },
  }
</script>
