export default {
  windowSize: state => state.settings.windowSize,

  // example
  getUserById: state => id => {
    let user = state.users.filter( item => item.id === id )[0]

    return !user ? null : user
  },
}
