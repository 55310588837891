import Vue from 'vue'
import flatpickr from 'flatpickr'
import VueflatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru'
import 'flatpickr/dist/flatpickr.css'

flatpickr.localize( Russian )
flatpickr.setDefaults( {
    dateFormat: 'Y-m-d',
    time_24hr: true,
  },
)

Vue.use( VueflatPickr )
