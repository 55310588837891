<template>
  <div class="row">

    <div class="col px-0 px-lg-3">
      <div class="shadow rounded bg-white py-3 py-md-4 px-lg-2 mb-4">

        <div class="col d-flex flex-wrap align-items-center mb-3">
          <span class="h5 mr-3"><i class="nav-icon icons8-find-user-male"></i> {{ title }}</span>
        </div>

        <div class="col">
          <v-server-table ref="userListTrashedTable"
                          :columns="columns"
                          :options="tableOptions"
                          :name="'user-list-deleted'">
            <button @click="restoreUser( props.row.id )"
                    :disabled="$wait.waiting('restoreUser')"
                    class="btn btn-success"
                    slot="action"
                    slot-scope="props">Восстановить
            </button>
          </v-server-table>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'UserListTrashed',

    metaInfo() {
      return {
        title: this.title,
      }
    },

    data() {
      return {
        title: 'Удаленные пользователи',
        columnsSettings:
          [
            {
              name: 'Email',
              alias: 'email',
              sortable: true,
              filterable: true,
              visible: true,
            },
            {
              name: 'Фамилия',
              alias: 'surname',
              sortable: true,
              filterable: true,
              visible: true,
            },
            {
              name: 'Имя',
              alias: 'name',
              sortable: true,
              filterable: true,
              visible: true,
            },
            {
              name: 'Отчество',
              alias: 'patronymic',
              sortable: true,
              filterable: true,
              visible: true,
            },
            {
              name: '',
              alias: 'action',
              sortable: false,
              filterable: false,
              visible: true,
            },
          ],
      }
    },

    computed: {
      tableOptions() {
        return {
          saveState: true,
          filterByColumn: true,
          skin: 'table table-bordered table-striped',
          filterable: this.filterableColumns,
          sortable: this.sortableColumns,
          headings: this.headings,
          perPage: 10,
          perPageValues: [10],
          pagination: { chunk: this.$store.getters.windowSize === 'large' ? 10 : 3 },
          columnsDropdown: false,
          columnsClasses: {
            id: 'mw-100',
          },
          sortIcon: {
            base: 'icons8',
            up: 'icons8-chevron-up',
            down: 'icons8-chevron-down',
            is: 'icons8-up-and-down-arrows',
          },

          responseAdapter( resp ) {
            let data = this.getResponseData( resp )
            let users = data.data.data
            let total = data.data.meta ? data.data.meta.pagination.total : 0

            return {
              data: users,
              count: total,
            }
          },

          requestFunction( params ) {
            let options = {
              params: params,
              paginate: 1,
            }

            return this.$api.user.findAllTrashedUsers( options )
          },
        }
      },

      filterableColumns() {
        return this.columnsSettings.filter( column => column.filterable ).map( column => column.alias )
      },

      sortableColumns() {
        return this.columnsSettings.filter( column => column.sortable ).map( column => column.alias )
      },

      headings() {
        let result = {}

        this.columnsSettings.forEach( column => {
          result[column.alias] = column.name
        } )

        return result
      },

      columns() {
        return this.columnsSettings.filter( column => column.visible ).map( column => column.alias )
      },
    },

    methods: {
      restoreUser( id ) {
        this.$wait.start( 'restoreUser' )

        this.$api.user.restoreUser( id ).then( res => {
          if ( res.status === 200 ) {
            this.$toasted.show( res.data.message, { type: 'success' } )
            this.$refs.userListTrashedTable.refresh()
          }
        } ).finally( () => {
          this.$wait.end( 'restoreUser' )
        } )
      },
    },
  }
</script>
