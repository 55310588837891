<template>
  <div class="events">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'Evens',

    metaInfo() {
      return {
        title: 'Мероприятия',
      }
    },
  }
</script>
