import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'
import localeRu from 'vee-validate/dist/locale/ru'

const config = {
  locale: 'ru',
  classes: true,
  classNames: {
    valid: '',
    invalid: 'is-invalid',
  },
  inject: true,
  aria: true,
  delay: 750,
}

Vue.use( VeeValidate, config )

Validator.localize( 'ru', localeRu )

