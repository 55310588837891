<template>
  <div class="shops">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'Shops',

    metaInfo() {
      return {
        title: 'Магазины',
      }
    },
  }
</script>
