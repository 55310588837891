<template>
  <div class="row" v-if="Object.keys( user ).length > 0">

    <div class="col-xl-6 px-0 px-lg-3">
      <div class="card shadow">
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center mb-4">
            <div class="card-title mr-3">
              <span>{{ fullName }}</span>
            </div>
            <span class="badge badge-info mr-3" v-if="$can(['read-role'])">{{ user.roles.data[0].name }}</span>
            <router-link :to="'/users/' + user.id + '/edit'"
                         v-if="$can(['update-user']) || isOwner"
                         class="btn btn-outline-primary">
              Изменить
            </router-link>
          </div>
          <div class="form-group row">
            <label class="col-12 col-xl-4 col-form-label text-muted">Email</label>
            <div class="col-12 col-xl-8">
              <input :value="user.email" type="text" readonly class="form-control-plaintext">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-xl-4 col-form-label text-muted">Магазин</label>
            <div class="col-12 col-xl-8">
              <input :value="user.shop.data ? user.shop.data.name : ''"
                     type="text" readonly class="form-control-plaintext">
            </div>
          </div>
          <div class="form-group row" v-if="!isUser">
            <label class="col-12 col-xl-4 col-form-label text-muted">Статус</label>
            <div class="col-12 col-xl-8">
              <input :value="user.status === 3 ? 'Активен' : 'Заблокирован'"
                     type="text" readonly class="form-control-plaintext">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-xl-4 col-form-label text-muted">С нами</label>
            <div class="col-12 col-xl-8">
              <input :value="user.created_at + ' (' + user.readable_created_at + ')'"
                     type="text" readonly class="form-control-plaintext">
            </div>
          </div>
          <div class="form-group row" v-if="!isUser">
            <label class="col-12 col-xl-4 col-form-label text-muted">Обновлен</label>
            <div class="col-12 col-xl-8">
              <input :value="user.updated_at + ' (' + user.readable_updated_at + ')'"
                     type="text" readonly class="form-control-plaintext">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-4 px-0 px-lg-3">
      <div class="card shadow">
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center">
            <div class="card-title">
              Персональный QR-код
            </div>
          </div>
          <div class="mb-2">
            <img :src="qrBase64" title="qrCode">
          </div>
          <a class="btn btn-success btn-block" download="qrcode.png" :href="qrBase64">
            <i class="icons8-download"></i>
            Сохранить
          </a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import QRCode from 'qrcode'
  import { mapGetters } from 'vuex'

  export default {
    name: 'UserDetail',

    metaInfo() {
      return {
        title: this.title,
      }
    },

    data() {
      return {
        title: '',
        user: {},
        qrBase64: '',
      }
    },

    computed: {
      ...mapGetters( {
        isUser: 'auth/isUser',
      } ),

      fullName() {
        return ( `${this.user.surname || ''} ${this.user.name}  ${this.user.patronymic || ''}` ).trim()
      },

      isOwner() {
        return this.$store.getters['auth/userId'] === this.$route.params.id
      },
    },

    created() {
      this.$wait.start( 'findUserById' )

      this.$api.user.findUserById( this.$route.params.id, 'roles,shop' ).then( res => {
        if ( res.status === 200 && res.data.data ) {
          this.user = res.data.data
          this.title = res.data.data.surname || res.data.data.name
        }
      } ).then( () => {
        let opts = {
          errorCorrectionLevel: 'M',
          type: 'image/png',
          rendererOpts: {
            quality: 0.9,
          },
        }

        let qrData = `uid=${this.user.id}&uem=${this.user.email}`

        QRCode.toDataURL( qrData, opts, ( err, url ) => {
          if ( err ) {
            throw err
          }

          this.qrBase64 = url
        } )
      } ).finally( () => {
        this.$wait.end( 'findUserById' )
      } )
    },

    beforeRouteEnter( to, from, next ) {
      if ( to.params.id.length !== parseInt( process.env.VUE_APP_HASH_ID_LENGTH ) ) {
        next( vm => {
          vm.$router.push( { name: 'NotFound' } )
        } )
      } else {
        return next()
      }
    },
  }
</script>
